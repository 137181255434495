import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Details } from '../../Components/Details/Details';

export const Dog = ({ match }) => {
  const { id } = match.params;
  const [dog, setDog] = useState(null);

  useEffect(() => {
    const getDog = async () => {
      const dogResponse = await axios.get(
        `https://mighty-mountain-77454.herokuapp.com/dogs/${id}`
      );
      setDog(dogResponse.data);
    };
    getDog();
  }, [id]);
  return dog ? <Details dog={dog} /> : <>Loading</>;
};
