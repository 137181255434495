import React from 'react';
import { Link } from 'react-router-dom';

export const Card = ({ dog }) => {
  return (
    <div className='card h-100' key={dog.description}>
      <Link to={`/dogs/${dog.id}`}>
        <img src={dog?.feature_image?.url} className='card-img-top' alt='...' />
      </Link>
      <div className='card-body'>
        <h5 className='card-title'>{dog.title}</h5>
        <Link to={`/dogs/${dog.id}`} className='btn btn-primary'>
          View puppy
        </Link>
      </div>
    </div>
  );
};
