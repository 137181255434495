import React from 'react';
import { Card } from '../Card/Card';

export const Cards = ({ dogs }) => {
  return (
    <div className='row'>
      {dogs.map((dog, idx) => (
        <div className='col-md-3 col-sm-12' key={idx}>
          <Card dog={dog} />
        </div>
      ))}
    </div>
  );
};
