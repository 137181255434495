import React from 'react';

export const Footer = () => {
  return (
    <footer
      className='bg-light'
      style={{ paddingTop: '10px', paddingBottom: '5px' }}
    >
      <div className='container'>
        <div className='text-right' style={{ fontSize: '10px' }}>
          Icons made by{' '}
          <a href='https://www.flaticon.com/authors/freepik' title='Freepik'>
            Freepik
          </a>{' '}
          from{' '}
          <a href='https://www.flaticon.com/' title='Flaticon'>
            www.flaticon.com
          </a>
        </div>
      </div>
    </footer>
  );
};
