import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Gallery } from '../../Components/Gallery/Gallery';
import { AvailableDogs } from '../../Components/List/AvailableDogs';

export const Home = () => {
  const [dogs, setDogs] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const getDogs = async () => {
      const dogs = await axios.get(
        'https://mighty-mountain-77454.herokuapp.com/dogs'
      );

      setDogs(dogs.data);
    };

    const getDescription = async () => {
      const result = await await axios.get(
        'https://mighty-mountain-77454.herokuapp.com/aboutpage'
      );

      setDescription(result.data?.body);
    };
    getDescription();
    getDogs();
  }, []);
  return (
    <div>
      <div className='jumbotron mt-3 text-center bg-light border'>
        <span className='display-4'>Frenchton Puppies</span>
        <img
          src='https://strapiplayground.s3.us-west-1.amazonaws.com/french_bulldog_logo01_e82d74b7a7.svg'
          width='50'
          height='50'
          className='d-inline-block align-top mt-3 ml-3'
          alt=''
          loading='lazy'
        />
        <hr className='my-4' />
        <div className='row lead'>
          <span className='col-md-4 col-sm-12'>frenchtondog1@gmail.com</span>
          <span className='col-md-4 col-sm-12'>(702)-330-9022</span>
          <span className='col-md-4 col-sm-12'>Las Vegas, Nevada</span>
          <hr className='col-sm-12 my-4' />
          <p className='col-sm-12'>{description}</p>
        </div>
      </div>
      <AvailableDogs dogs={dogs} />
      <Gallery trim={true} />
      <div className='row no-gutters my-4 text-center bg-light border'>
        <div className='col-md-12'>
          <div className='lead py-3'>
            <Link to={`/dogs/gallery`}>View Full Gallery</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
