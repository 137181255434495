import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Navbar } from './Components/Navbar/Navbar';
import { Home } from './Pages/Home/Home';
import { Dog } from './Pages/Dogs/Dog';
import { Dogs } from './Pages/Dogs/Dogs';

import history from './history';
import { Gallery } from './Components/Gallery/Gallery';
import { Footer } from './Components/Footer/Footer';

function App() {
  return (
    <>
      <Router history={history}>
        <Navbar />
        <div className='container'>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/dogs' component={Dogs} />
            <Route exact path='/dogs/gallery' component={Gallery} />
            <Route exact path='/dogs/:id' component={Dog} />
          </Switch>
        </div>
      </Router>
      <Footer />
    </>
  );
}

export default App;
