import React from 'react';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery.css';

export const Details = ({ dog }) => {
  const images = dog.gallery.map((galleryImage) => {
    return {
      thumbnail: galleryImage?.formats?.thumbnail?.url || galleryImage.url,
      original: galleryImage?.formats?.medium?.url || galleryImage.url,
    };
  });
  const formatDate = (date) => {
    const year = date.substring(0, 4);
    const monthThenDay = date.substring(5);
    return `${monthThenDay}-${year}`;
  };
  return (
    <div>
      <ImageGallery
        showNav={false}
        showPlayButton={false}
        showFullscreenButton={false}
        items={images}
      />
      <span style={{ whiteSpace: 'pre-line' }}>
        <p>Price: {dog.price ? `$${dog.price}` : 'Call for more info'}</p>
        <p>Gender: {dog.gender[0].toUpperCase() + dog.gender.slice(1)}</p>
        <p>Birthday: {formatDate(dog.birthday)}</p>
        <p>Availible: {formatDate(dog.availability)}</p>
        <p>{dog.description}</p>
      </span>
    </div>
  );
};
