import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const Gallery = ({ trim }) => {
  const [gallery, setGallery] = useState({});
  const [preview, setPreview] = useState([]);
  useEffect(() => {
    const getGallery = async () => {
      const galleryItems = await axios.get(
        `https://mighty-mountain-77454.herokuapp.com/gallery`
      );

      const result = galleryItems.data;
      const preview = result.media_files.slice(0, 3);
      setGallery(result);
      setPreview(preview);
    };
    getGallery();
  }, []);
  return (
    <>
      <div className='row no-gutters my-4 text-center bg-light border'>
        <div className='col-md-12'>
          <div className='lead py-3'>Gallery</div>
        </div>
      </div>

      {preview && trim && (
        <>
          <div className='row'>
            {preview.map((galleryItem, idx) => {
              return (
                <div className='col-md-4 col-sm-12 mb-3' key={idx}>
                  <img
                    src={galleryItem.formats.medium.url}
                    className='card-img'
                    alt='...'
                  />
                </div>
              );
            })}
          </div>
        </>
      )}

      {!trim && gallery && gallery.media_files && (
        <>
          <div className='row'>
            {gallery.media_files.map((galleryItem, idx) => {
              return (
                <div className='col-md-4 col-sm-12 mb-3' key={idx}>
                  <img
                    src={galleryItem.formats.medium.url}
                    className='card-img'
                    alt='...'
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
