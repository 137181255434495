import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Cards } from '../../Components/List/Cards/Cards';

export const Dogs = () => {
  const [dogs, setDogs] = useState(null);

  useEffect(() => {
    const getDogs = async () => {
      const dogs = await axios.get(
        'https://mighty-mountain-77454.herokuapp.com/dogs'
      );

      setDogs(dogs.data);
    };
    getDogs();
  }, []);

  return dogs ? (
    <div>
      <div className='text-center'>
        <h1>Check out this list of puppies</h1>
      </div>
      <Cards dogs={dogs} />
    </div>
  ) : (
    <>Loading</>
  );
};
