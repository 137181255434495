import React from 'react';
import { Cards } from './Cards/Cards';

export const AvailableDogs = ({ dogs }) => {
  const availableDogs = dogs.filter((dog) => !dog.sold);
  availableDogs.sort((dog1, dog2) => {
    return dog1.id - dog2.id;
  });

  console.log(availableDogs);
  return (
    <>
      <div className='row no-gutters my-4 text-center bg-light border'>
        <div className='col-md-12'>
          <div className='lead py-3'>Available Puppies</div>
        </div>
      </div>
      <Cards dogs={availableDogs} />
    </>
  );
};
