import React from 'react';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <div className='container'>
        <Link className='navbar-brand' to='/'>
          <img
            src='https://strapiplayground.s3.us-west-1.amazonaws.com/french_bulldog_logo01_e82d74b7a7.svg'
            width='30'
            height='30'
            className='d-inline-block align-top'
            alt=''
            loading='lazy'
          />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <div className='navbar-nav'>
            <Link className='nav-link active' to='/'>
              Home
            </Link>
            <Link className='nav-link active' to='/dogs/gallery'>
              Gallery
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};
